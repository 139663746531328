import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { FormControl, TextField, withStyles, Button, FormHelperText, Container, Typography } from '@material-ui/core'
import { changePassword } from '~/api'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },

    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },

    button: {
        margin: theme.spacing(2)
    },

    loader: {
        height: theme.typography.button.lineHeight,
        width: theme.typography.button.lineHeight,
        margin: theme.spacing(0, 1)
    },

    alert: {
        marginTop: theme.spacing(10)
    }
})

class ChangePassword extends Component {
    static propTypes = {}

    state = {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
        loading: false,
        changed: false,
        error: false,
        wrongPassword: false
    }

    getContent() {
        const { classes, t } = this.props
        const { oldPassword, newPassword1, newPassword2, loading, changed, error, wrongPassword } = this.state

        const newPasswordsEquals = newPassword1 === newPassword2
        const buttonActive = oldPassword !== '' && newPassword1 !== '' && newPasswordsEquals

        if (changed) {
            return (
                <Typography variant='h6' className={ classes.alert }>
                    { t('settings.password.success') }
                </Typography>
            )
        }

        if (error) {
            return (
                <Typography variant='h6' className={ classes.alert }>
                    { t('errors.requestError') }
                </Typography>
            )
        }

        return (
            <form className={ classes.form } noValidate onSubmit={ this.submitHandler }>
                <FormControl
                    margin='normal'
                    fullWidth
                    error={ wrongPassword }
                >
                    <TextField
                        required
                        error={ wrongPassword }
                        id='password'
                        label={ t('settings.password.currentPassword') }
                        name='password'
                        type='password'
                        value={ oldPassword }
                        onChange={ this.passwordChange }
                    />
                    { wrongPassword ? <FormHelperText>{ t('errors.wrongPassword') }</FormHelperText> : null }
                </FormControl>

                <FormControl
                    margin='normal'
                    fullWidth
                    error={ !newPasswordsEquals }
                >
                    <TextField
                        required
                        error={ !newPasswordsEquals }
                        id='newPassword1'
                        label={ t('settings.password.newPassword') }
                        name='newPassword1'
                        type='password'
                        value={ newPassword1 }
                        onChange={ this.newPassword1Change }
                    />
                    { !newPasswordsEquals ?
                        <FormHelperText>{ t('settings.password.passwordsInNotEqual') }</FormHelperText> : null }
                </FormControl>

                <TextField
                    margin='normal'
                    fullWidth
                    required
                    id='newPassword2'
                    label={ t('settings.password.newPasswordAgain') }
                    name='newPassword2'
                    type='password'
                    value={ newPassword2 }
                    onChange={ this.newPassword2Change }
                />

                <Button
                    variant='contained'
                    className={ classes.button }
                    color='primary'
                    onClick={ this.submitHandler }
                    disabled={ !buttonActive || loading }
                >
                    { t('settings.password.changePassword') }
                </Button>
            </form>
        )
    }

    render() {
        const { classes, t } = this.props

        return (
            <PageWrapper title={ t('settings.password.title') } backButton>
                <Container className={ classes.container }>
                    { this.getContent() }
                </Container>
            </PageWrapper>
        )
    }

    submitHandler = async e => {
        this.setState({ loading: true })

        const { oldPassword, newPassword1: newPassword } = this.state

        const { errorCode, ok } = await changePassword(oldPassword, newPassword).request()

        this.setState({
            loading: false,
            changed: ok,
            error: errorCode === -1,
            wrongPassword: errorCode === 2
        })
    }

    passwordChange = e => {
        this.setState({ oldPassword: e.target.value })
    }

    newPassword1Change = e => {
        this.setState({ newPassword1: e.target.value })
    }

    newPassword2Change = e => {
        this.setState({ newPassword2: e.target.value })
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(ChangePassword)
