import { Record } from 'immutable'
import { arrToMap } from './utils'
import { GADGETS_LOAD, GADGET_LOAD, GADGET_UPDATE, REQUEST, SUCCESS, FAIL, fieldUpdateStates } from '~/constants'

const UpdateState = Record({
    title: fieldUpdateStates.loaded
})

const GadgetRecord = Record({
    id: null,
    title: null,
    rawTitle: null,
    serialNumber: '',
    type: null,
    supportedMetrics: null,
    loading: false,
    loaded: false,
    error: false,
    fieldUpdateState: new UpdateState()
})

const ReducerRecord = Record({
    entities: arrToMap([], GadgetRecord),
    loading: false,
    loaded: false,
    error: false
})

const defaultState = new ReducerRecord()

export default function gadgetsReducer(state = defaultState, action) {
    const { type, payload } = action

    switch (type) {
        case REQUEST(GADGETS_LOAD):
            return state
                .set('loading', true)
                .set('error', false)

        case SUCCESS(GADGETS_LOAD):
            const { gadgets } = payload

            for (const gadget of gadgets) {
                gadget.rawTitle = gadget.title
                gadget.title = `${gadget.rawTitle} (${gadget.serialNumber})`
            }
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('entities', arrToMap(gadgets, GadgetRecord))

        case FAIL(GADGETS_LOAD):
            return state
                .set('loading', false)
                .set('error', true)

        case REQUEST(GADGET_LOAD): {
            const { id } = payload

            return state
                .setIn(['entities', id, 'loading'], true)
                .setIn(['entities', id, 'error'], false)
        }

        case SUCCESS(GADGET_LOAD): {
            const { id, title: rawTitle, serialNumber } = payload
            const title = `${rawTitle} (${serialNumber})`
            return state
                .setIn(['entities', id], new GadgetRecord({
                    ...payload,
                    title,
                    rawTitle,
                    loaded: true
                }))
        }

        case FAIL(GADGET_LOAD): {
            const { id } = payload
            return state
                .setIn(['entities', id, 'error'], true)
                .setIn(['entities', id, 'loading'], false)
        }

        case REQUEST(GADGET_UPDATE): {
            const { id } = payload
            return state
                .setIn(['entities', id, 'fieldUpdateState', 'title'], fieldUpdateStates.loading)
        }

        case SUCCESS(GADGET_UPDATE): {
            const { id, title: rawTitle } = payload
            const serialNumber = state.getIn(['entities', id, 'serialNumber'])
            const title = `${rawTitle} (${serialNumber})`
            return state
                .setIn(['entities', id], state
                    .getIn(['entities', id])
                    .set('title', title)
                    .set('rawTitle', rawTitle)
                )
                .setIn(['entities', id, 'fieldUpdateState', 'title'], fieldUpdateStates.loaded)
        }

        case FAIL(GADGET_UPDATE): {
            const { id } = payload
            return state
                .setIn(['entities', id, 'fieldUpdateState', 'title'], fieldUpdateStates.error)
        }

        default:
            return state
    }
}
