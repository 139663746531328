import {
    DASHBOARD_SET_BEGIN_DATE,
    DASHBOARD_SET_END_DATE,
    DASHBOARD_SET_SELECTED_SCALE_ID,
    METRIC_VALUES_LOAD
} from '~/constants'
import { getMetrics } from '~/api'

export function setBeginDate(beginDate) {
    return {
        type: DASHBOARD_SET_BEGIN_DATE,
        payload: {
            beginDate
        }
    }
}

export function setEndDate(endDate) {
    return {
        type: DASHBOARD_SET_END_DATE,
        payload: {
            endDate
        }
    }
}

export function setSelectedScaleId(selectedScaleId) {
    return {
        type: DASHBOARD_SET_SELECTED_SCALE_ID,
        payload: {
            selectedScaleId
        }
    }
}

export function loadMetricValues(beginDate, endDate, selectedScaleId) {
    return getMetrics(beginDate, endDate, selectedScaleId, METRIC_VALUES_LOAD).createAction()
}
