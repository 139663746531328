import { signOut } from '~/AC'

export const unauthorizedCheck = store => next => action => {
    const { payload } = action

    if (payload && payload.status === 403) {
        store.dispatch(signOut())
    }

    next(action)
}
