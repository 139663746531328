import React, { Component, Fragment } from 'react'
import { AppBar, Typography, Toolbar, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons'
import history from '~/history'

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,

    noLeftPadding: {
        paddingLeft: 0
    }
})

class PageWrapper extends Component {
    static propTypes = {}

    render() {
        const { classes, title, children, backButton } = this.props

        const toolbarContent =
            typeof title === 'string' ?
                <Typography component="h1" variant="h6" color="inherit">
                    { title }
                </Typography> :
                title

        const showBackButton = backButton && history.length

        return (
            <Fragment>
                <AppBar>
                    <Toolbar className={ showBackButton ? classes.noLeftPadding : null }>
                        { showBackButton ?
                            <IconButton onClick={ history.goBack }>
                                <ChevronLeftIcon/>
                            </IconButton> :
                            null }
                        { toolbarContent }
                    </Toolbar>
                </AppBar>
                <Fragment>
                    <div className={ classes.appBarSpacer }/>
                    { children }
                    <div className={ classes.appBarSpacer }/>
                </Fragment>
            </Fragment>
        )
    }

    handleBack = e => {

    }
}

export default withStyles(styles)(PageWrapper)
