import { GET_PERSONAL_DATA, PERSONAL_SET_NAME, PERSONAL_SET_PHONE, PERSONAL_SET_EMAIL } from '~/constants'
import { getPersonalData as getPersonalDataApi } from '~/api'

export function getPersonalData() {
    return getPersonalDataApi(GET_PERSONAL_DATA).createAction()
}

export function setName(name) {
    return {
        type: PERSONAL_SET_NAME,
        payload: { name }
    }
}

export function setPhone(phone) {
    return {
        type: PERSONAL_SET_PHONE,
        payload: { phone }
    }
}

export function setEmail(email) {
    return {
        type: PERSONAL_SET_EMAIL,
        payload: { email }
    }
}
