import { Record } from 'immutable'
import { SIGN_IN, SIGN_OUT, REQUEST, SUCCESS, FAIL } from '~/constants'

const localStorage = window.localStorage
const token = localStorage.getItem('token')

const ReducerRecord = Record({
    loggedIn: !!token,
    loading: false,
    token: token,
    wrongUsername: false,
    wrongPassword: false
})

const defaultState = new ReducerRecord()

export default function authReducer(state = defaultState, action) {
    const { type, payload } = action

    switch (type) {
        case REQUEST(SIGN_IN):
            return state.set('loading', true)

        case SUCCESS(SIGN_IN):
            const { token } = payload
            localStorage.setItem('token', token)
            return state
                .set('loading', false)
                .set('loggedIn', true)
                .set('token', token)

        case FAIL(SIGN_IN):
            const { response: { errorCode } } = payload

            return state
                .set('loading', false)
                .set('wrongUsername', errorCode === 1)
                .set('wrongPassword', errorCode === 2)

        case SIGN_OUT:
            window.localStorage.removeItem('token')
            return state.set('loggedIn', false)

        default:
            return state
    }
}
