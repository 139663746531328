import React, { Component } from 'react'
import { compose } from 'recompose'
import { List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { gadgetsSelector } from '~/selectors'
import { gadgetsCheckAndLoad } from '~/AC'
import { Switch, Route, Link } from 'react-router-dom'
import { Router as RouterIcon } from '@material-ui/icons'
import ScaleIcon from '~/icons/Scale'
import PageWrapper from '~/components/Layouts/PageWrapper'
import Gadget from './Gadget'
import Loader from '~/components/common/Loader'
import LoadingError from '~/components/common/LoadingError'
import { withTranslation } from 'react-i18next'

const styles = theme => ({})

class Gadgets extends Component {
    static propTypes = {}

    componentDidMount() {
        const {
            gadgetsCheckAndLoad,
            gadgetsLoading,
            gadgetsLoaded
        } = this.props

        if (gadgetsLoading || gadgetsLoaded) return

        gadgetsCheckAndLoad()
    }

    render() {
        const { gadgets, gadgetsLoaded, gadgetsLoading, gadgetsLoadingError, t } = this.props

        if (gadgetsLoading)
            return (
                <PageWrapper title={ t('devices.title') } backButton>
                    <Loader/>
                </PageWrapper>
            )

        if (gadgetsLoadingError)
            return (
                <PageWrapper title={ t('devices.title') } backButton>
                    <LoadingError />
                </PageWrapper>
            )

        if (!gadgetsLoaded)
            return null

        return (
            <Switch>
                <Route path="/settings/gadgets" exact>
                    <PageWrapper title={ t('devices.title') } backButton>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            { gadgets.map(({ id, title, type }) => (
                                <ListItem button component={ Link } to={ `/settings/gadgets/${ id }` } key={ id }>
                                    <ListItemIcon>
                                        {
                                            type === 'scale' ?
                                                <ScaleIcon/> :
                                                <RouterIcon/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText>{ title }</ListItemText>
                                </ListItem>
                            )) }
                        </List>
                    </PageWrapper>
                </Route>
                <Route
                    path="/settings/gadgets/:id"
                    render={
                        ({ match: { params: { id } } }) => {
                            return <Gadget id={ Number.parseInt(id) }/>
                        }
                    }
                />
            </Switch>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(state => ({
            gadgets: gadgetsSelector(state),
            gadgetsLoading: state.gadgets.loading,
            gadgetsLoaded: state.gadgets.loaded,
            gadgetsLoadingError: state.gadgets.error
        }),
        { gadgetsCheckAndLoad })
)(Gadgets)
