import { combineReducers } from 'redux'
import auth from './auth'
import gadgets from './gadgets'
import dashboard from './dashboard'
import personal from './personal'
import history from '~/history'
import { connectRouter } from 'connected-react-router'

export default combineReducers({
    router: connectRouter(history),
    auth,
    gadgets,
    dashboard,
    personal
})
