import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { withStyles, Container, Button, CircularProgress, TextField, FormControl } from '@material-ui/core'
import TitledValue from '~/components/common/TitledValue'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getPersonalData, setName, setPhone, setEmail } from '~/AC/personal'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    field: {
        margin: theme.spacing(1, 0)
    },

    container: {
        margin: 0,
        padding: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    form: {
        margin: 0,
        padding: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },

    button: {
        margin: theme.spacing(1)
    }
})

class Personal extends Component {
    static propTypes = {}

    state = {
        editing: false
    }

    componentDidMount() {
        const { personalDataLoaded, personalDataLoading, getPersonalData: loadPersonalData } = this.props

        if (personalDataLoaded || personalDataLoading)
            return null

        loadPersonalData()
    }

    getContent() {
        const { classes, personalDataLoading, name, phone, email, t } = this.props
        const { editing } = this.state

        if (personalDataLoading)
            return <Container>
                <CircularProgress
                    variant="indeterminate"
                    color="secondary"
                />
            </Container>

        if (editing) {
            return (
                <form className={ classes.container }>
                    <FormControl
                        fullWidth
                        className={ classes.field }
                    >
                        <TextField
                            required
                            id="name"
                            label="Имя"
                            name="name"
                            autoComplete="off"
                            value={ name }
                            onChange={ this.nameChange }
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={ classes.field }
                    >
                        <TextField
                            required
                            id="phone"
                            label="Телефон"
                            name="phone"
                            autoComplete="off"
                            value={ phone }
                            onChange={ this.phoneChange }
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={ classes.field }
                    >
                        <TextField
                            required
                            id="email"
                            label="Электронная почта"
                            name="email"
                            autoComplete="off"
                            value={ email }
                            onChange={ this.emailChange }
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        className={ classes.button }
                        color="primary"
                        onClick={ () => {
                        } }
                    >
                        { t('phrases.save') }
                    </Button>
                </form>
            )
        }

        return (
            <Container className={ classes.container }>
                <TitledValue
                    title="Имя"
                    value={ name }
                    className={ classes.field }
                />
                <TitledValue
                    title="Телефон"
                    value={ phone }
                    className={ classes.field }
                />
                <TitledValue
                    title="Электронная почта"
                    value={ email }
                    className={ classes.field }
                />
                <Button
                    variant="contained"
                    className={ classes.button }
                    color="primary"
                    onClick={ () => {
                        this.setState({ editing: true })
                    } }
                >
                    { t('settings.personal.changeData') }
                </Button>
            </Container>
        )
    }

    render() {
        return (
            <PageWrapper title="Личные данные" backButton>
                { this.getContent() }
            </PageWrapper>
        )
    }

    nameChange = e => {
        const { setName } = this.props

        setName(e.target.value)
    }

    phoneChange = e => {
        const { setPhone } = this.props

        setPhone(e.target.value)
    }

    emailChange = e => {
        const { setEmail } = this.props

        setEmail(e.target.value)
    }
}

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(state => ({
        personalDataLoading: state.personal.loading,
        personalDataLoaded: state.personal.loaded,
        personalDataError: state.personal.loadError,
        name: state.personal.name,
        phone: state.personal.phone,
        email: state.personal.email
    }), {
        getPersonalData,
        setName,
        setPhone,
        setEmail
    })
)(Personal)
