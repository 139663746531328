import { createStore, applyMiddleware } from 'redux'
import reducer from '~/reducer'
import { apiMiddleware } from 'redux-api-middleware'
import { tokenMiddleware } from './middlewares/token'
import { metricValuesLoaderMiddleware } from './middlewares/metricValuesLoader'
import { unauthorizedCheck } from './middlewares/unauthorizedCheck'
import { routerMiddleware } from 'connected-react-router'
import '~/moment'
import history from '~/history'

const store = createStore(
    reducer,
    {},
    applyMiddleware(
        routerMiddleware(history),
        tokenMiddleware,
        metricValuesLoaderMiddleware,
        apiMiddleware,
        unauthorizedCheck
    )
)

window.store = store

export default store
