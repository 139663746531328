import React, { Component } from 'react'
import {
    withStyles,
    Typography,
    TextField,
    Container,
    Button,
    //Link,
    FormControl,
    FormHelperText
} from '@material-ui/core'
import { signIn } from '~/AC'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const style = theme => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    submit: {
        margin: theme.spacing(5, 0),
        padding: theme.spacing(1, 5)
    }
})

class Auth extends Component {
    static propTypes = {}

    state = {
        username: '',
        password: ''
    }

    render() {
        const { classes, wrongUsername, wrongPassword, t } = this.props
        const { username, password } = this.state

        return (
            <Container component='main' maxWidth='xs' className={ classes.root }>
                <Typography component='h1' variant='h5'>
                    { t('auth.title') }
                </Typography>
                <form className={ classes.form } noValidate onSubmit={ this.submitHandler }>
                    <FormControl
                        margin='normal'
                        fullWidth
                        error={ wrongUsername }
                    >
                        <TextField
                            required
                            error={ wrongUsername }
                            id='phone'
                            label={ t('phrases.phoneNumber') }
                            name='phone'
                            autoComplete='phone'
                            autoFocus
                            value={ username }
                            onChange={ this.usernameHandler }
                        />
                        { wrongUsername ? <FormHelperText>{ t('auth.phoneNotFound') }</FormHelperText> : null }
                    </FormControl>

                    <FormControl
                        margin='normal'
                        fullWidth
                        error={ wrongPassword }
                    >
                        <TextField
                            required
                            error={ wrongPassword }
                            name='password'
                            label={ t('phrases.password') }
                            type='password'
                            id='password'
                            autoComplete='current-password'
                            value={ password }
                            onChange={ this.passwordHandler }
                        />
                        { wrongPassword ? <FormHelperText>{ t('errors.wrongPassword') }</FormHelperText> : null }
                    </FormControl>

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={ classes.submit }

                    >
                        { t('auth.login') }
                    </Button>

                    {/*<Link href="#" variant="body2" color="secondary" onClick={ this.forgotPasswordHandler }>*/ }
                    {/*    Забыли пароль?*/ }
                    {/*</Link> */ }
                </form>
            </Container>
        )
    }

    submitHandler = e => {
        const { username, password } = this.state

        this.props.signIn(username, password)
        e.preventDefault()
    }

    usernameHandler = e => {
        this.setState({
            username: e.target.value
        })
    }

    passwordHandler = e => {
        this.setState({
            password: e.target.value
        })
    }

    forgotPasswordHandler = e => {
        e.preventDefault()
    }
}

export default compose(
    withTranslation(),
    withStyles(style),
    connect(state => ({
        wrongUsername: state.auth.wrongUsername,
        wrongPassword: state.auth.wrongPassword
    }), { signIn })
)(Auth)
