import React, { Component } from 'react'
import MomentUtils from '@date-io/moment'
import { Grid, withStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { connect } from 'react-redux'
import { setBeginDate, setEndDate } from '~/AC/dashboard'
import moment from '~/moment'
import { compose } from 'recompose'

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        //padding: theme.spacing(0, 1)
    }
})

class DatePicker extends Component {
    static propTypes = {}

    render() {
        const { beginDate, endDate, setBeginDate, setEndDate } = this.props

        return (
            <MuiPickersUtilsProvider utils={ MomentUtils } locale={ moment.locale() } libInstance={ moment }>
                <div style={{padding: '0 15px'}}>
                    <Grid container spacing={ 1 }>
                        <Grid item xs={ 6 } style={{textAlign: 'right'}}>
                            <KeyboardDatePicker
                                format='DD.MM.YYYY'
                                margin='normal'
                                value={ beginDate }
                                onChange={ value => setBeginDate(value) }
                            />
                        </Grid>
                        <Grid item xs={ 6 } style={{textAlign: 'left'}}>
                            <KeyboardDatePicker
                                format='DD.MM.YYYY'
                                margin='normal'
                                value={ endDate }
                                onChange={ value => setEndDate(value) }
                            />
                        </Grid>
                    </Grid>
                </div>
            </MuiPickersUtilsProvider>
        )
    }
}

export default compose(
    withStyles(styles),
    connect(state => ({
        beginDate: state.dashboard.beginDate,
        endDate: state.dashboard.endDate
    }), { setBeginDate, setEndDate })
)(DatePicker)
