import { isRSAA, RSAA } from 'redux-api-middleware'

export const tokenMiddleware = store => next => action => {
    const state = store.getState()

    if (isRSAA(action) && state.auth.loggedIn) {
        const { headers = {} } = action[RSAA]

        action[RSAA].headers = {
            'Auth-token': state.auth.token,
            ...headers
        }
    }

    next(action)
}
