import { GADGETS_LOAD, GADGET_LOAD, GADGET_UPDATE } from '~/constants'
import { getGadgets, getGadget, updateGadget as apiUpdateGadget } from '~/api'

export function gadgetsCheckAndLoad() {
    return getGadgets(GADGETS_LOAD).createAction()
}

export function loadGadget(id) {
    return getGadget(id, GADGET_LOAD).createAction()
}

export function updateGadget(id, data) {
    return apiUpdateGadget(id, data, GADGET_UPDATE).createAction()
}