import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button
} from '@material-ui/core'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { Link, Route, Switch } from 'react-router-dom'
import Gadgets from './Gadgets'
import Personal from './Personal'
import ChangePassword from './ChangePassword'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { signOut } from '~/AC'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    list: {},

    dialog: {

    }
})

class Settings extends Component {
    static propTypes = {}

    state = {
        signOutDialogOpen: false
    }

    checkSignOut = e => {
        this.setState({
            signOutDialogOpen: true
        })
    }

    handleCloseSignOutDialog = e => {
        this.setState({
            signOutDialogOpen: false
        })
    }

    getSignOutDialog() {
        const { signOutDialogOpen: open } = this.state
        const { signOut, classes, t } = this.props

        return (
            <Dialog
                open={ open }
                onClose={ this.handleCloseSignOutDialog }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={ classes.dialog }
            >
                <DialogTitle id="alert-dialog-title">{ t('phrases.warning') }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { t('settings.logOutQuestion') }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ signOut } color="primary">
                        { t('phrases.yes') }
                    </Button>
                    <Button onClick={ this.handleCloseSignOutDialog } color="primary" autoFocus>
                        { t('phrases.no') }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { classes, t } = this.props

        return (
            <Switch>
                <Route path="/settings" exact>
                    <PageWrapper title={ t('settings.title') }>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItem button component={ Link } to="/settings/gadgets">
                                <ListItemText>
                                    { t('settings.devices') }
                                </ListItemText>
                            </ListItem>
                        </List>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    { t('settings.profile') }
                                </ListSubheader>
                            }
                            className={ classes.list }
                        >
                            {/*<ListItem button component={ Link } to="/settings/personal">*/}
                            {/*    <ListItemText>*/}
                            {/*        Личные данные*/}
                            {/*    </ListItemText>*/}
                            {/*</ListItem>*/}

                            <ListItem button component={ Link } to="/settings/password">
                                <ListItemText>
                                    { t('settings.changePassword') }
                                </ListItemText>
                            </ListItem>
                            <ListItem button onClick={ this.checkSignOut }>
                                <ListItemText>
                                    { t('settings.logOut') }
                                </ListItemText>
                            </ListItem>
                            { this.getSignOutDialog() }
                        </List>
                    </PageWrapper>
                </Route>
                <Route path="/settings/gadgets" component={ Gadgets } />
                <Route path="/settings/personal" component={ Personal } />
                <Route path="/settings/password" component={ ChangePassword }/>
            </Switch>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(null, { signOut })
)(Settings)
