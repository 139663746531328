import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import moment from '~/moment'

const defaultLng = 'en'
export const i18n = {
    lng: defaultLng
}
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: defaultLng,
        debug: false
    })

i18next.on('languageChanged', function(lng) {
    i18n.lng = lng
    moment.locale(lng)
})
