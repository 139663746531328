let lastFocusEvent = null
const normalHeight = window.innerHeight
let smallHeight = null
let virtualKeyboardDetectel = false

window.document.addEventListener('focus', e => {
    lastFocusEvent = new Date()
}, { capture: true })

window.addEventListener('resize', e => {
    if (!virtualKeyboardDetectel && lastFocusEvent && (new Date()).getTime() - lastFocusEvent.getTime() < 1000) {
        lastFocusEvent = null
        smallHeight = window.innerHeight
        virtualKeyboardDetectel = true
    }

    if (virtualKeyboardDetectel) {
        switch (window.innerHeight) {
            case normalHeight:
                dispatch('virtualKeyboardHide')
                break

            case smallHeight:
                dispatch('virtualKeyboardShow')
                break

            default:
                break
        }
    }
})

function dispatch(eventName) {
    const event = new Event(eventName)

    window.dispatchEvent(event)
}