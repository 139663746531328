import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import {
    Toolbar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import questionList from './questions.json'
import MarkdownView from 'react-showdown'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(0)
    },

    instruction: {
        margin: theme.spacing(0)
    },

    title: {
        flexGrow: 1
    }
})

class Faq extends Component {
    static propTypes = {}

    render() {
        const { classes, t } = this.props

        return (
            <PageWrapper title={
                <Toolbar className={ classes.toolbar }>
                    <Typography variant='h6' className={ classes.title } component='h1' noWrap>
                        { t('faq.title') }
                    </Typography>
                </Toolbar>
            }
            >
                {
                    questionList.map(({ q, a }) =>
                        <Accordion key={ q }>
                            <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
                                <Typography variant='subtitle2'>{ t(`faq.${ q }`) }</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MarkdownView
                                    markdown={ t(`faq.${ a }`) }
                                    options={ { tables: true, emoji: false } }
                                />
                            </AccordionDetails>
                        </Accordion>)
                }
            </PageWrapper>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(Faq)
