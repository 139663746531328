import React from 'react'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { yellow, grey } from '@material-ui/core/colors'
import { CssBaseline } from '@material-ui/core'
import AuthProvider from '~/components/Layouts/AuthProvider'
import { ConnectedRouter } from 'connected-react-router'
import history from '~/history'
import MainPages from '~/components/Layouts/MainPages'
import Loader from './components/common/Loader'

const style = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflowX: 'scroll'
    },

    bottomNavigation: {
        position: 'fixed',
        bottom: 0,
        width: '100%'
    }

    //appBarSpacer: theme.mixins.toolbar,
})

const theme = createMuiTheme({
    palette: {
        primary: yellow,
        secondary: grey
    }
})

function App(props) {
    const { classes } = props

    return (
        <React.Suspense fallback={<Loader />}>
            <MuiThemeProvider theme={ theme }>
                <ConnectedRouter history={ history }>
                    <AuthProvider>
                        <div className={ classes.root }>
                            <CssBaseline />
                            <MainPages />
                        </div>
                    </AuthProvider>
                </ConnectedRouter>
            </MuiThemeProvider>
        </React.Suspense>
    )
}

export default withStyles(style)(App)
