import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { withStyles, Card, CardContent, Typography, CardHeader } from '@material-ui/core'

const styles = theme => ({
    card: {
        margin: theme.spacing(2),
        maxWidth: 600
    }
})

class News extends Component {
    static propTypes = {}

    componentDidMount() {
        localStorage.setItem('news1', true)
    }

    render() {
        const { classes } = this.props

        return (
            <PageWrapper title="Новости">
                <Card className={ classes.card }>
                    <CardHeader
                        title="Последнее обновление"
                        subheader="30 июня 2019"
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Просим вас обновить цифровую станцию до версии 1.2.6
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Что сделано:
                            <ul>
                                <li>реализован режим пониженного потребления энергии</li>
                                <li>реализован режим непрерывной отправки данных когда станция стоит на зарядке</li>
                                <li>добавлена функция Reset (сброса) цифровой станции по двойному клику</li>
                            </ul>
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            А также:
                            <ul>
                                <li>добавлена функция «изменить пароль», см. раздел настройки</li>
                                <li>
                                    интерфейс графиков стал удобнее,
                                    исправлено масштабирование массы и атмосферного давления
                                </li>
                                <li>
                                    добавлена возможность управлять отображением метрик на графике (масса, температура,
                                    влажность и т.п.)
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Обновляйте скорее<span role="img" aria-label="ага">😉</span>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ classes.card }>
                    <CardHeader
                        title="Обновление цифровой станции"
                        subheader="24 июня 2019"
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Уважаемый пользователь! Ура!
                            Цифровую станцию пора обновить до версии 1.2.4
                            <ul>
                                <li>Исправлены ошибки «зависания станции»</li>
                                <li>Улучшена работоспособность, и другие радости</li>
                            </ul>
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Обновление происходит по bluetooth. Для этого необходимо находиться рядом со
                            станцией.
                            Зайдите в Настройки > Обновление станции.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Приятного использования!
                        </Typography>
                    </CardContent>
                </Card>
            </PageWrapper>
        )
    }
}

export default withStyles(styles)(News)
