export const REQUEST = type => `${ type }_REQUEST`
export const SUCCESS = type => `${ type }_SUCCESS`
export const FAIL = type => `${ type }_FAIL`

export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'

export const GADGETS_LOAD = 'GADGETS_LOAD'
export const GADGET_LOAD = 'GADGET_LOAD'
export const GADGET_UPDATE = 'GADGET_UPDATE'

export const DASHBOARD_DATES_LOADED = 'DASHBOARD_DATES_LOADED'
export const DASHBOARD_SET_BEGIN_DATE = 'DASHBOARD_SET_BEGIN_DATE'
export const DASHBOARD_SET_END_DATE = 'DASHBOARD_SET_END_DATE'
export const DASHBOARD_SET_SELECTED_SCALE_ID = 'DASHBOARD_SET_SELECTED_SCALE_ID'

export const METRIC_VALUES_LOAD = 'METRIC_VALUES_LOAD'

export const GET_PERSONAL_DATA = 'GET_PERSONAL_DATA'
export const SAVE_PERSONAL_DATA = 'SAVE_PERSONAL_DATA'
export const PERSONAL_SET_NAME = 'PERSONAL_SET_NAME'
export const PERSONAL_SET_PHONE = 'PERSONAL_SET_PHONE'
export const PERSONAL_SET_EMAIL = 'PERSONAL_SET_EMAIL'

export const ICONS_SIZE = 20

export const fieldUpdateStates = {
    loaded: 'loaded',
    loading: 'loading',
    error: 'error'
}