import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { Container, withStyles } from '@material-ui/core'
import TitledValue from '~/components/common/TitledValue'
import Loader from '~/components/common/Loader'
import LoadingError from '~/components/common/LoadingError'
import moment from 'moment'
import { getStationDetails } from '~/api'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    field: {
        margin: theme.spacing(1, 0)
    },

    container: {
        margin: 0,
        padding: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
})

class Scale extends Component {
    static propTypes = {}
    state = {
        detailsLoading: false,
        detailsLoaded: false,
        detailsLoadingError: false,
        batteryLevel: 0,
        signalStrength: 0,
        lastAction: null
    }

    async componentDidMount() {
        const { gadget: { id } } = this.props

        this.setState({
            detailsLoading: true,
            detailsLoadingError: false
        })

        try {
            const details = await getStationDetails(id).request()

            if (this.setState)
                this.setState({
                    detailsLoading: false,
                    detailsLoaded: true,
                    ...details
                })
        } catch (e) {
            if (this.setState)
                this.setState({
                    detailsLoading: false,
                    detailsLoadingError: true
                })
        }
    }

    componentWillUnmount() {
        this.setState = undefined
    }

    getContent() {
        const { gadget: { serialNumber, id, rawTitle }, classes, t } = this.props
        const {
            detailsLoaded,
            detailsLoading,
            detailsLoadingError,
            batteryLevel,
            signalStrength,
            lastAction
        } = this.state

        if (detailsLoading)
            return <Loader/>

        if (detailsLoadingError)
            return <LoadingError/>

        if (!detailsLoaded)
            return null

        return (
            <Container className={ classes.container }>
                <TitledValue
                    title={ t('devices.deviceTitle') }
                    value={ rawTitle }
                    editable={ true }
                    id={ id }
                    className={ classes.field }
                />

                <TitledValue
                    title={ t('devices.serial') }
                    value={ serialNumber }
                    className={ classes.field }
                />

                <TitledValue
                    title={ t('devices.batteryLevel') }
                    value={ batteryLevel ? `${ batteryLevel }%` : t('phrases.unknown') }
                    className={ classes.field }
                    warning={ batteryLevel && batteryLevel < 30 ? t('devices.lowBattery') : null }
                />

                <TitledValue
                    title={ t('devices.cellularSignalStrength') }
                    value={ signalStrength ? `${ signalStrength }%` : t('phrases.unknown') }
                    className={ classes.field }
                />

                <TitledValue
                    title={ t('devices.lastTimeOnline') }
                    value={ lastAction ? moment(lastAction).fromNow() : t('phrases.unknown') }
                    className={ classes.field }
                />
            </Container>
        )
    }

    render() {
        const { gadget: { title } } = this.props

        return (
            <PageWrapper title={ title } backButton>
                { this.getContent() }
            </PageWrapper>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(Scale)
