import React, { Component } from 'react'
import { Typography, Container, IconButton, Input, InputAdornment } from '@material-ui/core'
import { Create, Send } from "@material-ui/icons"
import { updateGadget } from '~/AC'
import { connect } from "react-redux"
import { ICONS_SIZE, fieldUpdateStates } from '~/constants'

class TitledValue extends Component {
    static propTypes = {}

    state = {
        value: this.props.value,
        isEditing: false
    }

    sendValue = () => {
        const { updateGadget, id } = this.props
        const { value } = this.state
        this.setState({
            isEditing: false
        })
        if (value !== '')
            updateGadget(id, { title: value })
        else
            this.setState({
                value: this.props.value
            })
    }

    getValue(id) {
        const { value } = this.state
        if (this.state.isEditing) {
            return (<Input
                id="standard-helperText"
                style={ { marginTop: "5px" } }
                defaultValue={ value }
                onChange={
                    (event) => {
                        const { value } = event.target
                        this.setState({
                            value: value
                        })
                    }
                }
                inputProps={ {
                    onBlur: this.sendValue,
                    onKeyPress: (event) => {
                        if (event.key === 'Enter') {
                            this.sendValue()
                        }
                    }
                } }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="delete"
                            onClick={ this.sendValue }>
                            <Send style={ { fontSize: ICONS_SIZE } }/>
                        </IconButton>
                    </InputAdornment>
                }
                autoFocus={ true }
            />)
        }
        return (<React.Fragment>
            { this.state.value }
            <IconButton
                aria-label="delete"
                onClick={ () => {
                    this.setState({
                        isEditing: true
                    })
                } }>
                <Create style={ { fontSize: ICONS_SIZE } }/>
            </IconButton>
        </React.Fragment>)
    }

    getLoadStatus() {
        const { updateState } = this.props
        switch (updateState) {
            case fieldUpdateStates.loaded:
                return ''
            case fieldUpdateStates.loading:
                return ' loading...'
            case fieldUpdateStates.error:
                return ' error'
            default:
                return ''
        }
    }

    render() {
        const { title, children, value, rawValue, warning, id, editable, ...props } = this.props
        const { isEditing } = this.state
        return (
            <Container { ...props } style={ { marginBottom: (isEditing ? "8px" : "1px") } }>
                <Typography variant="caption" display="block" gutterBottom color="textSecondary">
                    { title + this.getLoadStatus() }
                </Typography>
                <Typography component={ 'span' } variant="body1" display="block" gutterBottom>
                    { children || (editable ? this.getValue(id) : value) }
                </Typography>
                {
                    warning ?
                        (<Typography variant="caption" display="block" gutterBottom color="error">
                            { warning }
                        </Typography>) :
                        null
                }
            </Container>
        )
    }
}

export default connect((state, ownProps) => ({
    updateState: state.gadgets.getIn(['entities', ownProps.id, 'fieldUpdateState', 'title'])
}), { updateGadget })(TitledValue)
