import React, { Component } from 'react'
import { CircularProgress, Container, withStyles } from '@material-ui/core'

const styles = theme => ({
    container: {
        margin: 'auto',
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
})

class Loader extends Component {
    static propTypes = {}

    render() {
        const { classes } = this.props
        return (
            <Container className={ classes.container }>
                <CircularProgress
                    variant='indeterminate'
                    color='secondary'
                />
            </Container>
        )
    }
}

export default withStyles(styles)(Loader)
