import React, { Component } from 'react'
import { connect } from 'react-redux'
import Auth from '~/components/Auth'

class AuthProvider extends Component {
    static propTypes = {}

    render() {
        const { children, loggedIn } = this.props

        if (!loggedIn)
            return <Auth />

        return children
    }
}

export default connect(state => {
    return {
        loggedIn: state.auth.loggedIn
    }
})(AuthProvider)
