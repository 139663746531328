import React, { Component } from 'react'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { connect } from 'react-redux'
import { gadgetSelector } from '~/selectors'
import { loadGadget } from '~/AC'
import Loader from '~/components/common/Loader'
import Scale from './Scale'
import Station from './Station'
import LoadingError from '~/components/common/LoadingError'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

class Gadget extends Component {
    static propTypes = {}

    componentDidMount() {
        const { gadget, loadGadget, t } = this.props

        if (!gadget)
            return t('devices.deviceNotFound')

        const { id, loaded, loading } = gadget

        if (!loaded && !loading) {
            loadGadget(id)
        }
    }

    getContent() {
        const { gadget: { loading, title } } = this.props

        if (loading)
            return <Loader />

        return `${ title }`
    }

    render() {
        const { gadget, t } = this.props

        if (!gadget)
            return t('devices.deviceNotFound')

        const { loading, loaded, error, title, type } = gadget

        if (loading)
            return (
                <PageWrapper title={ title } backButton>
                    <Loader />
                </PageWrapper>
            )

        if (error)
            return (
                <PageWrapper title={ title } backButton>
                    <LoadingError />
                </PageWrapper>
            )

        if (!loaded)
            return null

        if (type === 'scale')
            return <Scale gadget={ gadget } />

        if (type === 'station')
            return <Station gadget={ gadget } />
    }
}

export default compose(
    withTranslation(),
    connect((state, ownProps) => ({
        gadget: gadgetSelector(state, ownProps)
    }), { loadGadget })
)(Gadget)
