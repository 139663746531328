import React, { Component, Fragment } from 'react'
import {
    BottomNavigation,
    BottomNavigationAction,
    Badge
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { BarChart, EventNote, ContactSupport, Settings as SettingsIcon } from '@material-ui/icons'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import Dashboard from '~/components/Routes/Dashboard'
import News from '~/components/Routes/News'
import Faq from '~/components/Routes/Faq'
import Settings from '~/components/Routes/Settings'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const style = theme => ({
    bottomNavigation: {
        position: 'fixed',
        bottom: 0,
        width: '100%'
    },
})

class MainPages extends Component {
    static propTypes = {}

    constructor(props) {
        super(props)

        window.addEventListener('virtualKeyboardHide', () => {
            this.setState({ virtualKeyboardShowed: false })
        })

        window.addEventListener('virtualKeyboardShow', () => {
            this.setState({ virtualKeyboardShowed: true })
        })
    }


    state = {
        virtualKeyboardShowed: false
    }

    getNewsIcon() {
        const { classes } = this.props

        if (!localStorage.getItem('news1'))
            return <Badge className={ classes.padding } color="primary" badgeContent={ 1 }>
                <EventNote/>
            </Badge>

        return <EventNote/>
    }

    render() {
        const { virtualKeyboardShowed } = this.state
        const { classes, pathname, t } = this.props

        return (
            <Fragment>
                <Route path="/" exact render={
                    () => <Redirect to="/dashboard"/>
                }/>

                <Switch>
                    <Route path="/dashboard" component={ Dashboard }/>
                    <Route path="/news" component={ News }/>
                    <Route path="/faq" component={ Faq }/>
                    <Route path="/settings" component={ Settings }/>
                </Switch>
                { virtualKeyboardShowed ? null :
                    <BottomNavigation className={ classes.bottomNavigation } value={ pathname }>
                        <BottomNavigationAction component={ Link } to="/dashboard" label={ t('bottomNavigation.charts') } value="dashboard"
                                                icon={ <BarChart/> }/>
                        {/*<BottomNavigationAction*/}
                        {/*    component={ Link }*/}
                        {/*    to="/news"*/}
                        {/*    label="Новости"*/}
                        {/*    value="news"*/}
                        {/*    icon={ this.getNewsIcon() }*/}
                        {/*/>*/}
                        <BottomNavigationAction component={ Link } to="/faq" label={ t('bottomNavigation.faq') } value="faq"
                                                icon={ <ContactSupport/> }/>
                        <BottomNavigationAction component={ Link } to="/settings" label={ t('bottomNavigation.settings') } value="settings"
                                                icon={ <SettingsIcon/> }/>
                    </BottomNavigation>
                }
            </Fragment>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(style),
    connect(state => {
        const { pathname = '' } = state.router.location
        const match = pathname.match(/\/(settings|news|faq|dashboard)/)
        return {
            pathname: match && match[1]
        }
    })
)(MainPages)
