import { RSAA } from 'redux-api-middleware'
import { REQUEST, SUCCESS, FAIL } from '~/constants'
import store from '~/store'

const apiVersion = 'v1'
const apiEndpointRoot = `/api/${ apiVersion }`

class ApiRequest {
    constructor({
                    endpoint,
                    method = 'GET',
                    body,
                    type,
                    payload = {}
                }) {
        this.endpoint = endpoint
        this.method = method
        this.body = body
        this.type = type
        this.payload = payload
    }

    createAction() {
        const { endpoint, method, body, type, payload } = this
        return {
            [RSAA]: {
                endpoint: `${ apiEndpointRoot }${ endpoint }`,
                method,
                headers: { 'Content-Type': 'application/json' },
                body: body ? JSON.stringify(body) : undefined,
                types: [
                    {
                        type: REQUEST(type),
                        payload
                    }, {
                        type: SUCCESS(type)
                    }, {
                        type: FAIL(type)
                    }
                ]
            }
        }
    }

    async request() {
        const { endpoint, method, body } = this
        const state = store.getState()

        const response = await fetch(`${ apiEndpointRoot }${ endpoint }`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Auth-token': state.auth.token
            },
            body: body ? JSON.stringify(body) : undefined
        })

        return await response.json()
    }
}

export const auth = (username, password, type) => new ApiRequest({
    endpoint: '/auth/',
    method: 'POST',
    body: {
        username,
        password
    },
    type
})

export const getGadgets = (type) => new ApiRequest({
    endpoint: '/gadgets/',
    type
})

export const getGadget = (id, type) => new ApiRequest({
    endpoint: `/gadgets/${ id }`,
    type,
    payload: { id }
})

export const updateGadget = (id, data, type) => new ApiRequest({
    endpoint: `/gadgets/${ id }`,
    method: 'PATCH',
    body: data,
    type,
    payload: { id }
})

export const getMetrics = (beginDate, endDate, selectedScaleId, type) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    let endpoint = `/metrics?startDate=${ beginDate.format('YYYY-MM-DD') }&endDate=${ endDate.format('YYYY-MM-DD') }&timezone=${ encodeURIComponent(timeZone) }`

    if (selectedScaleId !== 'all') {
        endpoint += `&scaleId=${ selectedScaleId }`
    }

    return new ApiRequest({
        endpoint,
        type
    })
}

export const getPersonalData = type => new ApiRequest({
    endpoint: '/personal/',
    type
})

export const changePassword = (oldPassword, newPassword) => new ApiRequest({
    endpoint: `/personal`,
    method: 'POST',
    body: {
        oldPassword,
        newPassword
    }
})

export const getScaleDetails = (scaleId) => new ApiRequest({
    endpoint: `/gadgets/${ scaleId }/scale`
})

export const getStationDetails = (stationId) => new ApiRequest({
    endpoint: `/gadgets/${ stationId }/station`
})