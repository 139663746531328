import React from 'react'
import ReactDOM from 'react-dom'
import App from '~/App'
import * as serviceWorker from '~/serviceWorker'
import store from '~/store'
import { Provider } from 'react-redux'
import '~/virtualKeyboardEvents'
import '~/gt'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import '~/i18n'

Sentry.init({
    dsn: 'https://e061d3def0bb4255ba400889af1f183a@o488481.ingest.sentry.io/5550345',
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
})

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()


