import { Record } from 'immutable'
import {
    GET_PERSONAL_DATA,
//    SAVE_PERSONAL_DATA,
    REQUEST,
    SUCCESS,
    FAIL,
    PERSONAL_SET_NAME,
    PERSONAL_SET_PHONE,
    PERSONAL_SET_EMAIL
} from '~/constants'

const ReducerRecord = Record({
    loading: false,
    loaded: false,
    loadError: false,
    saving: false,
    saved: false,
    saveError: false,

    name: '',
    phone: '',
    email: ''
})

const defaultState = new ReducerRecord()

export default function personalReducer(state = defaultState, action) {
    const { type, payload } = action

    switch (type) {
        case REQUEST(GET_PERSONAL_DATA):
            return state.set('loading', true)

        case SUCCESS(GET_PERSONAL_DATA):
            const { ok, ...userData } = payload
            return state
                .set('loading', false)
                .set('loaded', true)
                .merge(userData)

        case FAIL(GET_PERSONAL_DATA):
            return state
                .set('loading', false)
                .set('loadError', true)

        case PERSONAL_SET_NAME:
            const { name } = payload
            return state.set('name', name)

        case PERSONAL_SET_PHONE:
            const { phone } = payload
            return state.set('phone', phone)

        case PERSONAL_SET_EMAIL:
            const { email } = payload
            return state.set('email', email)

        default:
            return state
    }
}
