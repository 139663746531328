import { SIGN_IN, SIGN_OUT } from '~/constants'
import * as api from '~/api'

export function signIn(username, password) {
    return api.auth(username, password, SIGN_IN).createAction()
}

export function signOut() {
    return {
        type: SIGN_OUT
    }
}
