import React, { Component } from 'react'
import { Container, Typography, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
    container: {
        margin: 'auto',
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
})

class LoadingError extends Component {
    static propTypes = {}

    render() {
        const { classes, t } = this.props
        return (
            <Container className={ classes.container }>
                <Typography variant='h5' align='center'>
                    { t('errors.loadingDataError') }
                </Typography>
                <Typography variant='body2' align='center'>
                    { t('errors.loadingDataErrorDescription') }
                </Typography>
            </Container>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(LoadingError)
