import React, { Component } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { gadgetsCheckAndLoad } from '~/AC/gadgets'
import { setSelectedScaleId, loadMetricValues } from '~/AC/dashboard'
import { gadgetsScaleSelector } from '~/selectors'
import { compose } from 'recompose'
import DatePicker from '~/components/Dashboard/DatePicker'
import Chart from '~/components/Dashboard/Chart'
import PageWrapper from '~/components/Layouts/PageWrapper'
import { withTranslation } from 'react-i18next'

const style = theme => ({
    scaleSelect: {
        width: '100%'
    }
})

class Dashboard extends Component {
    static propTypes = {}

    componentDidMount() {
        const {
            gadgetsCheckAndLoad,
            gadgetsLoading,
            gadgetsLoaded,
            loadMetricValues,
            beginDate,
            endDate,
            selectedScaleId
        } = this.props

        if (gadgetsLoading || gadgetsLoaded) return

        gadgetsCheckAndLoad()
        loadMetricValues(beginDate, endDate, selectedScaleId)
    }

    render() {
        const { classes, scales, selectedScaleId, setSelectedScaleId, t } = this.props

        const scaleItems = scales.map(({ id, title }) => <MenuItem value={ id } key={ id }>{ title }</MenuItem>)

        return (
            <PageWrapper
                title={
                    <Select value={ selectedScaleId } className={ classes.scaleSelect }
                            onChange={ e => setSelectedScaleId(e.target.value) }>
                        <MenuItem value='all' key='all'>{ t('phrases.allScales') }</MenuItem>
                        { scaleItems }
                    </Select>
                }
            >
                <DatePicker />
                <Chart />
            </PageWrapper>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(style),
    connect(state => ({
            scales: gadgetsScaleSelector(state),
            gadgetsLoading: state.gadgets.loading,
            gadgetsLoaded: state.gadgets.loaded,
            selectedScaleId: state.dashboard.selectedScaleId,
            beginDate: state.dashboard.beginDate,
            endDate: state.dashboard.endDate
        }),
        { gadgetsCheckAndLoad, setSelectedScaleId, loadMetricValues })
)(Dashboard)
