import { DASHBOARD_SET_SELECTED_SCALE_ID, DASHBOARD_SET_BEGIN_DATE, DASHBOARD_SET_END_DATE } from '~/constants'
import { loadMetricValues } from '~/AC/dashboard'

export const metricValuesLoaderMiddleware = store => next => action => {
    next(action)

    if (action.type === DASHBOARD_SET_BEGIN_DATE ||
        action.type === DASHBOARD_SET_END_DATE ||
        action.type === DASHBOARD_SET_SELECTED_SCALE_ID)
    {
        const state = store.getState()
        const { beginDate, endDate, selectedScaleId } = state.dashboard

        store.dispatch(loadMetricValues(beginDate, endDate, selectedScaleId))
    }
}
