import React, { Component, Fragment } from 'react'
import {
    Bar,
    BarChart,
    LineChart,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    Legend
} from 'recharts'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { gadgetsScaleSelector } from '~/selectors'
import moment from '~/moment'
import { Typography, Container, Chip } from '@material-ui/core'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'

const colors = [
    '#8884d8',
    '#ffc658',
    '#83a6ed',
    '#d0ed57',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c'
]

const [weightColor, temperatureColor, humidityColor, pressureColor] = colors

const chartSizes = {
    width: '100%',
    height: 300
}

const styles = theme => ({
    gainsChart: {
        width: chartSizes.width,
        height: `${ chartSizes.height }px`,
        //margin: theme.spacing(0),
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    chipsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },

    chip: {
        margin: theme.spacing(1)
    },

    weightChip: {
        background: weightColor,
        '&:hover': {
            background: weightColor
        },
        '&:focus': {
            background: weightColor
        }
    },

    temperatureChip: {
        background: temperatureColor,
        '&:hover': {
            background: temperatureColor
        },
        '&:focus': {
            background: temperatureColor
        }
    },

    humidityChip: {
        background: humidityColor,
        '&:hover': {
            background: humidityColor
        },
        '&:focus': {
            background: humidityColor
        }
    },

    pressureChip: {
        background: pressureColor,
        '&:hover': {
            background: pressureColor
        },
        '&:focus': {
            background: pressureColor
        }
    }
})

class Chart extends Component {
    static propTypes = {}

    constructor() {
        super()

        const selectedLines = localStorage.getItem('selectedLines')

        this.state = {
            selectedLines: selectedLines ?
                JSON.parse(selectedLines) :
                {
                    weight: true,
                    temperature: true,
                    humidity: false,
                    pressure: false
                }
        }
    }

    render() {
        const {
            classes,
            scales,
            gains,
            metricValues,
            selectedScaleId,
            beginDate,
            endDate,
            selectedScale,
            loading,
            error,
            t
        } = this.props
        const { selectedLines } = this.state

        localStorage.setItem('selectedLines', JSON.stringify(selectedLines))

        const {
            weight: weightSelected,
            temperature: temperatureSelected,
            humidity: humiditySelected,
            pressure: pressureSelected
        } = selectedLines

        if (error) {
            return (
                <Container className={ classes.gainsChart }>
                    <Typography variant='h5' align='center'>
                        { t('errors.loadingDataError') }
                    </Typography>
                    <Typography variant='body2' align='center'>
                        { t('errors.loadingDataErrorDescription') }
                    </Typography>
                </Container>
            )
        }

        const bars = selectedScaleId === 'all'
            ? scales.map(({ id, title }, index) => (
                <Bar dataKey={ id } key={ id } fill={ colors[index] } name={ title } unit={ t('units.kg') }>
                    { gains.length * scales.length <= 7 ?
                        <LabelList dataKey={ id } key={ id } position='center' /> :
                        null }
                </Bar>
            ))
            : (
                <Bar
                    dataKey={ selectedScaleId }
                    key={ selectedScaleId }
                    fill={ colors[0] }
                    name={ selectedScale.title }
                    unit={ t('units.kg') }
                >
                    { gains.length <= 7 ?
                        <LabelList dataKey={ selectedScaleId } key={ selectedScaleId } position='center' /> :
                        null }
                </Bar>
            )

        const gainsChart = gains.length
            ? (
                <ResponsiveContainer
                    width={ chartSizes.width }
                    height={ chartSizes.height }
                >
                    <BarChart data={ gains }>
                        <Tooltip
                            labelFormatter={ (value) => {
                                return moment(value).format('DD.MM')
                            } }
                        />
                        { bars }
                        {/*<CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>*/ }
                        <XAxis dataKey='date' tickFormatter={ value => moment(value).format('DD.MM') } />
                        <YAxis width={ 40 } />

                        <Legend verticalAlign='top' height={ 50 } />
                    </BarChart>
                </ResponsiveContainer>
            )
            : loading ? null : <Container className={ classes.gainsChart }>
                <Typography variant='h5' align='center'>
                    { t('dashboard.leakOfData') }
                </Typography>
                <Typography variant='body2' align='center'>
                    { t('dashboard.leakOfDataDescription') }
                </Typography>
                {
                    selectedScaleId === 'all' ?
                        <Typography variant='body2' align='center'>
                            { t('dashboard.leakOfDataNotice') }
                        </Typography> :
                        null
                }
            </Container>

        const supportedMetrics = selectedScaleId !== 'all' ? selectedScale.supportedMetrics : []

        const valuesChart =
            selectedScaleId !== 'all' ?
                metricValues.length !== 0 ?
                    (
                        <Fragment>
                            <Container className={ classes.chipsContainer }>
                                <Chip
                                    size='small'
                                    label={ t('metrics.weight') }
                                    clickable
                                    onClick={ this.handleChipClick('weight') }
                                    className={ weightSelected ? clsx(classes.chip, classes.weightChip) : classes.chip }
                                />
                                <Chip
                                    size='small'
                                    label={ t('metrics.temperature') }
                                    clickable
                                    onClick={ this.handleChipClick('temperature') }
                                    className={ temperatureSelected ? clsx(classes.chip, classes.temperatureChip) : classes.chip }
                                />
                                {
                                    supportedMetrics.indexOf('humidity') !== -1 ?
                                        <Chip
                                            size='small'
                                            label={ t('metrics.humidity') }
                                            clickable
                                            onClick={ this.handleChipClick('humidity') }
                                            className={ humiditySelected ? clsx(classes.chip, classes.humidityChip) : classes.chip }
                                        /> :
                                        null
                                }
                                {
                                    supportedMetrics.indexOf('pressure') !== -1 ?
                                        <Chip
                                            size='small'
                                            label={ t('metrics.pressure') }
                                            clickable
                                            onClick={ this.handleChipClick('pressure') }
                                            className={ pressureSelected ? clsx(classes.chip, classes.pressureChip) : classes.chip }
                                        /> :
                                        null
                                }
                            </Container>
                            <ResponsiveContainer
                                width={ chartSizes.width }
                                height={ chartSizes.height }
                            >
                                <LineChart data={ metricValues }>
                                    <YAxis yAxisId='weight' hide={ true } type='number' domain={ ['auto', 'auto'] } />
                                    <YAxis yAxisId='temperature' hide={ true } type='number' />
                                    <YAxis yAxisId='humidity' hide={ true } type='number' />
                                    <YAxis yAxisId='pressure' hide={ true } type='number' domain={ ['auto', 'auto'] } />

                                    {
                                        weightSelected ?
                                            <Line
                                                dataKey='weight'
                                                type='monotone'
                                                yAxisId='weight'
                                                stroke={ weightColor }
                                                name={ t('metrics.weight') }
                                                unit={ t('units.kg') } dot={ false } connectNulls={ true } strokeWidth={ 2 }
                                            /> :
                                            null
                                    }

                                    {
                                        temperatureSelected ?
                                            <Line
                                                dataKey='temperature'
                                                type='monotone'
                                                yAxisId='temperature'
                                                stroke={ temperatureColor }
                                                name={ t('metrics.temperature') } unit={ '\u2103' } dot={ false }
                                                strokeWidth={ 2 }
                                            /> :
                                            null
                                    }

                                    {
                                        humiditySelected && supportedMetrics.indexOf('humidity') !== -1 ?
                                            <Line
                                                dataKey='humidity'
                                                type='monotone'
                                                yAxisId='humidity'
                                                stroke={ humidityColor }
                                                name={ t('metrics.humidity') } unit='%' dot={ false } strokeWidth={ 2 }
                                            /> :
                                            null
                                    }

                                    {
                                        pressureSelected && supportedMetrics.indexOf('pressure') !== -1 ?
                                            <Line
                                                dataKey='pressure'
                                                type='monotone'
                                                yAxisId='pressure'
                                                stroke={ pressureColor }
                                                name={ t('metrics.pressure') } unit={ t('units.mm') } dot={ false } strokeWidth={ 2 }
                                            /> :
                                            null
                                    }
                                    {/*<CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>*/ }
                                    <XAxis dataKey={ data => moment.utc(data.date).valueOf() } type='number' domain={ ['dataMin', 'dataMax'] } scale='time' tickFormatter={ value => moment(value).format('DD.MM') } />

                                    <Tooltip
                                        labelFormatter={ (value) => {
                                            return moment(value).format('DD.MM HH:mm')
                                        } }
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Fragment>
                    )
                    : loading ? null : (
                        <Container className={ classes.valuesChart }>
                            <Typography variant='h5' align='center'>
                                { t('dashboard.noDataFromScale', {
                                    from: beginDate.format('DD.MM.YY'),
                                    to: endDate.format('DD.MM.YY')
                                }) }
                            </Typography>
                        </Container>
                    )
                : null

        return (
            <Fragment>
                { gainsChart }
                { valuesChart }
            </Fragment>
        )
    }

    handleChipClick = type => e => {
        const { selectedLines } = this.state
        const value = selectedLines[type]

        this.setState({
            selectedLines: {
                ...selectedLines,
                [type]: !value
            }
        })
    }
}

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(state => {
        const selectedScaleId = state.dashboard.selectedScaleId

        return {
            selectedScale: selectedScaleId !== 'all' ? state.gadgets.entities.get(selectedScaleId) : null,
            selectedScaleId,
            gains: state.dashboard.gains,
            metricValues: state.dashboard.metricValues,
            scales: gadgetsScaleSelector(state),
            beginDate: state.dashboard.beginDate,
            endDate: state.dashboard.endDate,
            loading: state.dashboard.loading,
            error: state.dashboard.error
        }
    })
)(Chart)
